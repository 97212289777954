<script>
import {mapActions, mapState} from 'vuex'
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
/**
 * Checkout Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect
  },
  data() {
    return {
     user:{
      name:"",
      lastname:"",
      email:"",
      password:"",
      roles: null,
      },
      options: [
        "Admin",
        "Marketer",
        "Designer",
        "Copy Writter",
      ],
    };
  },
    computed: {
   ...mapState(['error'])
  },
  methods: {
    ...mapActions(['newUser']),
    },
};
</script>
<style>
.banner{
background-image: url('../../../assets/images/banner.svg');
min-height: 650px !important;
margin-right: -24px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
      border-top-left-radius: 35px;
    border-bottom-left-radius: 35px;
}
.title-new-account{
  font-size: 40px;
    margin: 0 0 0px 0;
    font-weight: bold;
    color: #181818;
}
.subtitle-new-account{
  font-size: 18px;
    font-weight: bold;
    color: #a7adb7;
    margin-top: 10px;
}
.dot-new-account{
  color:#3521b5
}
.input-new-account{
  background-color: #f2f6fc;
  border-color: #f2f6fc;
  height: 50px;
  color:black !important;
  font-weight: bold !important;
  font-size: 17px !important;
  border-radius: 15px;
}
.icon-new-account{
color:#c8d0df !important;
}
.title-input-new-account{
color:#c5c9ce
}
.card-register{
    border-top-right-radius: 35px;
    border-bottom-right-radius: 35px;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 70px;
}
body{
    background-color: #ffffff;
}
</style>
<template>
  <Layout>
    <PageHeader/>
    <div class="row">
       <div class="col-lg-4">
          <div class="banner">
          </div>
      </div>
      <div class="col-lg-8">
        <div class="">
          <div style="background-color:#f9faff; boder-raio" class="card-register card-body">
                <div class="tab-pane" id="billing-info">
                  <p style="color:#ff4768" v-if="this.error !== 'New user successfully created'" class="subtitle-new-account">{{error}}</p>
                  <p style="color:green" v-if="this.error == 'New user successfully created'" class="subtitle-new-account">{{error}}</p>
                  <h5 class="title-new-account">Create new account<span class="dot-new-account">.</span></h5>
                  <form @submit.prevent="newUser(user)" style="margin-top:20px;">
                    <div>
                      <div>
                       <p class="subtitle-new-account">Complete the personal account information.</p>
                        <div class="row">                         
                          <div class="col-lg-5">
                              <div class="form-group auth-form-group-custom mb-4">
                            <label class="title-input-new-account" for="username">First Name</label>
                            <input v-model="user.name"
                              type="text"
                              class="input-new-account form-control"
                              id="firstname"
                              placeholder="Enter First Name"
                            />
                            <i class="ri-user-2-line auti-custom-input-icon icon-new-account"></i>
                          </div>
                          </div>
                              
                          <div class="col-lg-5">
                              <div class="form-group auth-form-group-custom mb-4">
                            <label class="title-input-new-account" for="username">Last Name</label>
                            <input
                              v-model="user.lastname"
                              type="text"
                              class="input-new-account form-control"
                              id="lastname"
                              placeholder="Enter Last Name"
                            />
                            <i class="icon-new-account ri-user-2-line auti-custom-input-icon "></i>
                          </div>
                          </div>
                          <div class="col-lg-5">
                                 <div class="form-group auth-form-group-custom mb-4">
                            <i class="icon-new-account ri-mail-line auti-custom-input-icon"></i>
                            <label class="title-input-new-account" for="useremail">Email</label>
                            <input 
                              v-model="user.email"
                              type="email"
                              class="input-new-account form-control"
                              id="useremail"
                              placeholder="Enter email"
                            />
                          </div>
                          </div>
                          <div class="col-lg-5">
                          
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="icon-new-account ri-lock-2-line auti-custom-input-icon"></i>
                            <label class="title-input-new-account"  for="userpassword">Password</label>
                            <input
                              v-model="user.password"
                              type="password"
                              class="input-new-account form-control"
                              id="userpassword"
                              placeholder="Enter password"
                            />
                            <div          
                              class="invalid-feedback"
                            >Password is required.</div>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                    
                       <p class="subtitle-new-account">Assigns the roles that the user can have.</p>
                        <div class="row">
                            <div class="col-lg-6">
                              <div class="form-group auth-form-group-custom mb-4">
<multiselect v-model="user.roles" :options="options" :multiple="true"></multiselect>
                              </div></div>
                        </div>
                      </div>
                    </div>
                        <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >Create new account</button>
                      
                  </form>
                </div>
            
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>